<template>
    <div v-if="editFastHouse">
        <div class="text-white w-96 rounded-md">
            <h1 class="text-2xl px-3 font-semibold">Редактировать быстрый адрес</h1>
            <div class="flex justify-between items-center my-4 px-3">
                <label class="text-sm">Название</label>
                <input placeholder="Название" v-model="editFastHouse.name"  class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text">
            </div>
            <div v-if="categories" class="flex justify-between items-center my-4 px-3">
                <label class="text-sm">Категория</label>
                <v-select
                class="w-[270px] text-black bg-white"
                :options="categories"
                v-model="editFastHouse.category.id"
                :reduce="category => category.id"
                placeholder="Категория"
                label="name"></v-select>
            </div>
            <!-- <div class="flex justify-between my-4 px-3">
                <input type="checkbox" class="mt-1.5 mr-2">
                <div>Показывать на карте вместо улицы и номера дома </div>
            </div>  -->
            <div v-if="streets" class="flex justify-between items-center my-4 px-3">
                <label class="text-sm">Улица</label>
                <v-select
                class="w-[270px] text-black bg-white"
                :options="streets"
                v-model="editFastHouse.streetCode"
                :reduce="options => options"
                @input="getDescription()"
                placeholder="Улица"
                label="displayName"></v-select>
            </div>
            <div v-if="home_numbers" class="flex justify-between items-center my-4 px-3">
                <label class="text-sm">Номер дома</label>
                <v-select
                class="w-[270px] text-black bg-white"
                :options="home_numbers"
                placeholder="Номер дома"
                v-model="editFastHouse.addressCode"
                @input="getDescription()"
                :reduce="options => options"
                label="houseNum"></v-select>
            </div>
            <div class="flex justify-between items-center my-4 px-3">
                <div class="text-sm">Координаты</div>
                <input disabled class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text" v-model="textCoordinates" >
            </div>
            <div class="my-1 px-3">
                <div class="text-sm">Описание <i class="required" >*</i></div>
                <textarea v-model="editFastHouse.description"  placeholder="Описание" class="border rounded w-full text-sm text-black px-2 py-1 h-[60px]" type="text"></textarea>
            </div>
            <!-- <div class="flex my-4 px-3">
                <div class="bg-white text-black rounded-sm mr-2 p-1 cursor-pointer">Места встреч (0)</div>
                <div class="bg-white text-black rounded-sm mr-2 p-1 cursor-pointer" @click="showSynonyms">Синонимы ({{lines.length}})</div>
            </div> -->
            <div v-if="synonymModal">
                <h1 class="text-2xl px-3 font-semibold">Синонимы</h1>
                <div class="flex justify-between my-4 px-3" v-for="(line, index) in lines" :key="index">
                    <input class="w-80 text-black" type="text" v-model="line.name">
                    <div class="bg-white text-black rounded-sm mr-1 px-2 cursor-pointer"><i class="ri-delete-bin-line" @click="removeLine(index)"></i></div>
                </div>
                <div class="flex justify-end my-4 px-3">
                    <div class="bg-white text-black rounded-sm mr-1 py-1 px-3 cursor-pointer" @click="addLine">+</div>
                </div>
            </div>
            <div class="flex my-4 px-3">
                <div
                @click="$emit('close')"
                class="bg-white text-black rounded-sm mr-2 p-1 cursor-pointer">Отмена</div>
                <div class="bg-white text-black rounded-sm p-1 cursor-pointer ml-auto" @click="editAddress(1)">Заблокировать</div>
                <button @click="editAddress(0)" :disabled="editRequestLoading" class="bg-black/40 text-white rounded mx-2 p-1 cursor-pointer">Сохранить</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['village_id', 'coordinateDragendMarkerFast','categories'],
    data() {
        return {
            editFastHouse: null,
            editRequestLoading: false,
            home_numbers: null,
            synonymModal: false,
            lines: [],
            name: '',
            lat: '',
            lng: '',
            textCoordinates: '',
            streets: []
        }
    },
    methods: {
        getFastAddress(id){
            this.$http.get(`v1/poi/points/${id.id}`)
            .then((res) => {
                this.editFastHouse = {
                    ...res.data,
                    description: res.data.description ? res.data.description : ''
                }
                this.textCoordinates = `${this.editFastHouse.lat}, ${this.editFastHouse.lng}`
                this.getHomeNumbers()
                this.getStreets()
            })
            .catch((error) => {
                console.log(error);
            })  
        },
        getStreets() {
            this.$http.get(`v1/streets/around`, { params: { lng: this.editFastHouse.lng, lat: this.editFastHouse.lat } })
            .then((res) => {
                this.streets = res.data
            })
            .catch((error) => {
                console.log(error);
            })
        },
        getHomeNumbers() {
            this.$http.get(`v1/poi/addresses?lng=${this.editFastHouse.lng}&lat=${this.editFastHouse.lat}`)
            .then((res) => {
                this.home_numbers = res.data
            })
            .catch((error) => {
                console.log(error);
            })
        },
        getDescription() {
            if(this.editFastHouse.streetCode && this.editFastHouse.addressCode) {
                this.editFastHouse.description = `${this.editFastHouse.streetCode.displayName}, ${this.editFastHouse.addressCode.houseNum}`
            }
        },
        showSynonyms() {
            this.synonymModal = !this.synonymModal
        },
        addLine() {
            this.lines.push({name: ''})
        },
        removeLine(index) { 
            this.lines.splice(index, 1)
        },
        editAddress(blocked) {
            this.editRequestLoading = true
            let data = {
                addressCode: this.editFastHouse.addressCode.addressCode,
                name: this.editFastHouse.name,
                postCode: this.editFastHouse.postCode,
                description: this.editFastHouse.description,
                categoryId: this.editFastHouse.category.id,
                streetCode: this.editFastHouse.streetCode.streetCode,
                lat: this.editFastHouse.lat,
                lng: this.editFastHouse.lng,
                blocked: blocked,
                id: this.editFastHouse.id
            }
            this.$http.patch(`v1/poi/points/${this.editFastHouse.id}`, data).then((res)=>{
                this.editRequestLoading = false
                this.$toast.open({
                    message: 'Адрес успешно изменен',
                    type: 'success',
                    position: 'top'
                });
                this.$emit('close')
                this.$emit('updateMarker', blocked, res.data, this.editFastHouse.id)
            }).catch(err=>{
                this.editRequestLoading = false
                this.$toast.open({
                    message: err.response.data.errors,
                    type: 'error',
                    position: 'top'
                });
            }).finally(() => {
                this.editRequestLoading = false
            })
        },
    },
    watch: {
        coordinateDragendMarkerFast(newValue) {
            this.editFastHouse.lat = newValue.lat
            this.editFastHouse.lng = newValue.lng
            this.textCoordinates = `${newValue.lat}, ${newValue.lng}`
            this.getHomeNumbers()
        }
    },
    computed: {
        coordinates() {
            return JSON.stringify([this.editFastHouse.lat, this.editFastHouse.lng]) 
        },
    },
    clearData() {
        this.editFastHouse = null,
        this.editRequestLoading = false,
        this.home_numbers = null,
        this.synonymModal = false,
        this.lines = [],
        this.name = '',
        this.lat = '',
        this.lng = '',
        this.textCoordinates = ''
    }
}
</script>