<template>
  <div class="toolbar" @mouseleave="clearToolbar">
    <ul style="list-style-type: none" class="flex mr-4">
      <li class="element__toolbar" @mouseover="openToolbars('open')" @click="closeToolbars()">
        <i class="ri-chat-3-fill icon__toolbar"></i>
      </li>
      <li class="element__toolbar" @mouseover="openToolbars('openMapMenu')" @click="closeToolbars()">
        <i class="ri-stack-fill icon__toolbar"></i>
      </li>
      <li class="element__toolbar" @click="$emit('zoomPlus')">
        <i class="ri-add-fill icon__toolbar"></i>
      </li>
      <li class="element__toolbar" @click="$emit('zoomMinus')">
        <i class="ri-subtract-fill icon__toolbar"></i>
      </li>
    </ul>

    <ul style="list-style-type: none" class="flex mr-4">
      <li class="element__toolbar" @mouseover="openToolbars('openPolygonMenu')" :class="editVillageCity ? 'colorbg' : ''" @click="closeToolbars()">
        <i class="ri-shape-fill icon__toolbar"></i>
      </li>
      <li class="element__toolbar" :class="isEditing ? 'colorbg' : ''" @mouseover="openToolbars('openEditRayon')" @click="closeToolbars()">
        <i class="ri-grid-fill icon__toolbar"></i>
      </li>
      <li class="element__toolbar" @mouseover="openToolbars('openDistrict')" :class="editDistrictStreets ? 'colorbg' : ''" @click="closeToolbars()">
        <i class="ri-home-gear-fill icon__toolbar"></i>
      </li>
      <li class="element__toolbar" :class="quickAddresses ? 'colorbg' : ''" @mouseover="openToolbars('openFastAddress')" @click="closeToolbars()">
        <i class="ri-flag-fill icon__toolbar"></i>
      </li>
      <li class="element__toolbar" :class="routerIsActive ? 'colorbg' : ''" @mouseover="openToolbars('openRoute')" @click="closeToolbars()">
        <i class="ri-guide-fill icon__toolbar"></i>
      </li>
      <li class="element__toolbar" :class="lineIsActive ? 'colorbg' : ''" @mouseover="openToolbars('openLinear')" @click="closeToolbars()">
        <i class="ri-ruler-fill icon__toolbar"></i>
      </li>
      <li class="element__toolbar">
        <i class="ri-route-fill icon__toolbar"></i>
      </li>
    </ul>


    <div class="absolute bottom-[43px] z-[9999] ml-[516px]" v-if="toolbarElements.eye">
      <div class="w-60 py-2.5 bg-blue-900 color text-white text-center">
        Всегда показать
      </div>
      <element-menu :icon="'ri-shape-fill'" :is_active="homeCenterPolygon" @click="$emit('polygonDisabled'), toolbarElements.eye = false">
        Границы города
      </element-menu>
    </div> 

    <ul style="list-style-type: none" class="flex mr-4">
      <li class="element__toolbar"  :class="homeCenterPolygon ? 'colorbg' : ''" @mouseover="openToolbars('eye')">
        <i class="ri-eye-fill icon__toolbar"></i>
      </li>
    </ul>

    <ul style="list-style-type: none" class="flex mr-4">
      <li class="element__toolbar" @mouseover="openToolbars('pen')">
        <i class="ri-pencil-fill icon__toolbar"></i>
      </li>
      <li class="element__toolbar">
        <i class="ri-question-mark icon__toolbar"></i>
      </li>
      <li class="element__toolbar" @click="logout">
        <i class="ri-logout-box-r-fill icon__toolbar"></i>
      </li>
    </ul>

    <div class="absolute bottom-[43px] z-[9999]" v-if="toolbarElements.open">
      <div class="w-60 py-2.5 bg-blue-900 color text-white text-center">
        Язык
      </div>
      <element-menu :icon="'ri-chat-3-fill'">
        Русский
      </element-menu>
      <!-- <element-menu :icon="'ri-chat-3-fill'">
        English
      </element-menu>
      <element-menu :icon="'ri-chat-3-fill'">
        France
      </element-menu> -->
    </div>

    <div class="absolute bottom-[43px] z-[9999] ml-11" v-if="toolbarElements.openMapMenu">
      <div class="w-60 py-2.5 bg-blue-900 color text-white text-center">
        Карты
      </div>
      <element-menu :icon="'ri-stack-fill'" @click="$emit('styleMap', 'Mtile Gram'), toolbarElements.openMapMenu = false">
        Mtile Gram
      </element-menu>
      <!-- <element-menu :icon="'ri-stack-fill'" @click="$emit('styleMap', 'OpenStreetMap.de'), toolbarElements.openMapMenu = false">
        OpenStreetMap.de
      </element-menu> -->
      <!-- <element-menu :icon="'ri-stack-fill'" @click="$emit('styleMap', 'Bing'), toolbarElements.openMapMenu = false">
        Bing
      </element-menu> -->
      <element-menu :icon="'ri-stack-fill'" @click="$emit('styleMap', 'Google'), toolbarElements.openMapMenu = false" >
        Google
      </element-menu>
      <element-menu :icon="'ri-stack-fill'" @click="$emit('styleMap', 'Google Earth'), toolbarElements.openMapMenu = false" >
        Google Earth
      </element-menu>
      <element-menu :icon="'ri-stack-fill'" @click="$emit('styleMap', 'Yandex'), toolbarElements.openMapMenu = false">
        Yandex
      </element-menu>
      <element-menu :icon="'ri-stack-fill'" @click="$emit('styleMap', 'Yandex Hybrid'), toolbarElements.openMapMenu = false">
        Yandex Hybrid
      </element-menu>
    </div>

    <div class="absolute bottom-[43px] z-[9999] ml-48" v-if="toolbarElements.openPolygonMenu">
      <div class="w-60 py-2.5 bg-blue-900 color text-white text-center">
        Границы города
      </div>
      <element-menu :icon="'ri-shape-fill'" :class="editVillageCity ? 'colorbg' : ''" @click="$emit('editPolygon'), toolbarElements.openPolygonMenu = false">
        Редактировать 
      </element-menu>
    </div>

    <div class="absolute bottom-[43px] z-[9999] ml-[236px]" v-if="toolbarElements.openEditRayon">
      <div class="w-60 py-2.5 bg-blue-900 color text-white text-center">
        Районы
      </div>
      <element-menu :icon="'ri-grid-fill'" :is_active="editZones" @click="editZones ? $emit('removeDistricts') : $emit('showDistricts'), toolbarElements.openEditRayon = false">
        Редактировать 
      </element-menu>
      <element-menu :icon="'ri-grid-fill'" :is_active="editedZone" @click="$emit('editZone'), toolbarElements.openEditRayon = false">
        Группировать в зоны
      </element-menu>
      <element-menu :icon="'ri-grid-fill'" :is_active="addDistrict" @click="$emit('createDistrict'), toolbarElements.openEditRayon = false">
        Новый район
      </element-menu>
    </div>

    <div class="absolute bottom-[43px] z-[9999] ml-[280px]" v-if="toolbarElements.openDistrict">
      <div class="w-60 py-2.5 bg-blue-900 color text-white text-center">
        Дома, улицы
      </div>
      <element-menu :icon="'ri-home-gear-fill'" :is_active="editHomes" @click="$emit('addHome'), toolbarElements.openDistrict = false">
        Редактировать дома
      </element-menu>
      <element-menu :icon="'ri-home-gear-fill'"  :is_active="addHomeSteets" @click="$emit('addLocation'), toolbarElements.openDistrict = false">
        Добавить дома, улицы
      </element-menu>
      <!-- <element-menu :icon="'ri-home-gear-fill'" :is_active="editStreets"  @click="$emit('addStreet'), toolbarElements.openDistrict = false">
        Редактор улиц
      </element-menu> -->
    </div>

    <div class="absolute bottom-[43px] z-[9999] ml-[324px]" v-if="toolbarElements.openFastAddress">
      <div class="w-60 py-2.5 bg-blue-900 color text-white text-center">
        Быстрые адреса
      </div>
      <element-menu :icon="'ri-flag-fill'" :is_active="addLocSpeedaddress" @click="$emit('editFast'), toolbarElements.openFastAddress = false">
        Редактировать БА
      </element-menu>
      <element-menu :icon="'ri-flag-fill'" :is_active="addFastAddress"  @click="$emit('addFast'), toolbarElements.openFastAddress = false">
        Добавить БА
      </element-menu>
    </div>

    <!-- <div class="absolute bottom-[43px] z-[9999] ml-[368px]" v-if="toolbarElements.openRoute">
      <div class="w-60 py-2.5 bg-blue-900 color text-white text-center">
        Маршрут
      </div>
      <element-menu :icon="'ri-flag-fill'" 
      @click="$emit('editRouter', toolbarElements.openRoute = false)"
      >
        Маршрут
      </element-menu>
    </div> -->

    <!-- <div class="absolute bottom-[43px] z-[9999] ml-[412px]" v-if="toolbarElements.openLinear">
      <div class="w-60 py-2.5 bg-blue-900 color text-white text-center">
        Линейка
      </div>
      <element-menu :icon="'ri-flag-fill'" @click="$emit('lineCreate', toolbarElements.openLinear = false)">
        Линейка
      </element-menu>
    </div> -->

    <div class="absolute bottom-[43px] z-[9999] ml-[516px]" v-if="toolbarElements.openEye">
      <div class="w-60 py-2.5 bg-blue-900 color text-white text-center">
        Показать
      </div>
      <element-menu :icon="'ri-eye-fill'" @click="$emit('eyeDistricts'), toolbarElements.openEye = false">
        Показать районы
      </element-menu>
      <element-menu :icon="'ri-eye-fill'" @click="$emit('eyeAddreses'), toolbarElements.openEye = false">
        Показать адреса
      </element-menu>
      <element-menu :icon="'ri-eye-fill'" @click="$emit('eyeFastAdress'), toolbarElements.openEye = false">
        Показать БА
      </element-menu>
    </div>

    <div class="absolute bottom-[43px] z-[9999] ml-[576px]" v-if="toolbarElements.pen">
      <div class="w-60 py-2.5 bg-blue-900 color text-white text-center">
        Редакторы карт
      </div>
      <a href="https://yandex.ru/maps/geo/3881784100/?ll=70.312436%2C40.488145&z=9" target="_blank">
        <element-menu :icon="'ri-eye-fill'" @click="toolbarElements.pen = false">
            Yandex Maps
        </element-menu>
      </a>
    </div>
  </div>
</template>
<script>
import ElementMenu from '../ElementMenu.vue'
import deleteLocalStorage from '@/util/deleteLocalStorage'
// import { mapActions } from 'vuex';
export default {
  props: [ 'routerIsActive', 'lineIsActive', 'homeCenterPolygon','editVillageCity','editZones', 'editedZone','addDistrict', 'editHomes', 'addHomeSteets', 'editStreets', 'addLocSpeedaddress', 'addFastAddress'],
  components: {
    ElementMenu,
  },
    data() {
      return {
        toolbarElements: {
          open: false,
          openMapMenu: false,
          openPolygonMenu: false,
          openEditRayon: false,
          openDistrict: false,
          openFastAddress: false,
          openRoute: false,
          openLinear: false,
          openEye: false,
          eye: false,
          pen: false,
        },
      }
    },
    methods: {
      // ...mapActions('district', [ 'sendCreationEvent']),
      clearToolbar() {
        this.toolbarElements = {
          open: false,
          openMapMenu: false,
          openPolygonMenu: false,
          openEditRayon: false,
          openDistrict: false,
          openFastAddress: false,
          openRoute: false,
          openLinear: false,
          openEye: false,
          eye: false,
          pen: false,
        }
      },
      // createDistrict(){
      //   this.sendCreationEvent(true);
      // },
      openToolbars(elementName) {
        for (const [key] of Object.entries(this.toolbarElements)) {
          if(key === elementName){
            this.toolbarElements[elementName] = true
          }
          
          else
            this.toolbarElements[key] = false
        }
      },
      closeToolbars() {
        for (const [key] of Object.entries(this.toolbarElements)) {
          this.toolbarElements[key] = false
        }
      },
      logout() {
        this.$http.get('v1/auth/logout')
        .then((res) => {
          if(res.status == 200) {
            localStorage.removeItem('login_data')
            deleteLocalStorage()
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$store.state.isAuthenticate = false
          deleteLocalStorage()
        })
      }
    },
    computed: {
      isEditing() {
        return this.editZones || this.editedZone || this.addDistrict;
      },
      editDistrictStreets() {
        return this.editHomes || this.addHomeSteets || this.editStreets
      },
      quickAddresses() {
        return this.addLocSpeedaddress || this.addFastAddress
      }
    }
}
</script>

<style>
.toolbar{
  position: fixed;
  bottom: 0px;
  left: calc(50% - 359px);
  z-index: 9999;
}
.element__toolbar{
  background: rgba(0 0 0 / 75%);
  padding: 6px 12px;
}
.icon__toolbar{
  color: #fff;
  font-size: 20px;
}
.element__toolbar:hover{
  background: rgba(0 0 0 / 50%);
}
.colorbg {
  background: #4cae4c;
}
</style>
