<template><div></div></template>

<script>
import L from 'leaflet';
import calculateTriangleCentroid from '@/util/calculateTriangleCentroid.js'
export default {
    props:['map'],
    data() {
        return {
            status: false,
            number: 0,
            numberMarket: 0,
            numberPolygon: 0,
            polygons: [],
            center: [],
            markerCenter: [],
            numbers: 1,
            customIcon: null,
            polygon: []
        }
    },
    methods: {
        addDistricts() {
            this.customIcon = L.divIcon({
                className: 'custom-marker',
                html: '<div style="background-color: #ffff; width: 15px; height: 15px; border-radius: 10%; border: 1px solid black;"></div>',
                iconSize: [10, 10],
                iconAnchor: [10, 10]
            });
            this.status = true
            this.map.on('click', (e) => {
                this.status ? this.addCreatePolygon(e) : ''
            })
        },
        addCreatePolygon(e) {
            if(this.polygons.length == 0) {
                this.polygons.push({
                    id: ++this.number,
                    color: 'blue',
                    polygonObject: null, 
                    markerObject: [],
                    coordinatesMarker: [{ id: ++this.numberMarket, coords: [e.latlng]}], 
                    coordinatesPolygon: [{ id: ++this.numberPolygon, coords: [[e.latlng.lat, e.latlng.lng]]}],
                    centerMarker: [[e.latlng.lat, e.latlng.lng]]
                });
                
                const newIndex = this.numberPolygon;
                const marker = L.marker([this.polygons[0].coordinatesMarker[0].coords[0].lat, this.polygons[0].coordinatesMarker[0].coords[0].lng], {
                    draggable: true,
                    icon: this.customIcon
                }).addTo(this.map)

                this.polygons[0].markerObject.push(marker)
                
                marker.on('dblclick',() => this.stopCreateMarkerMain())
                                
                
                this.polygon = L.polygon(this.polygons[0].coordinatesPolygon[0].coords, {
                    color:  this.polygons[0].color,
                    fillColor: this.polygons[0].color,
                    fillOpacity: 0.3
                }).addTo(this.map)
    
                
                this.polygons[0].polygonObject = this.polygon;


                this.attachDragEvent(marker, newIndex)
            }else {
                
                const polygonData = this.polygons[0];
                polygonData.coordinatesPolygon.push({id: ++this.numberPolygon, coords: [[e.latlng.lat, e.latlng.lng]]});
                polygonData.centerMarker.push([e.latlng.lat, e.latlng.lng])
                const newIndex = this.numberPolygon;

                polygonData.polygonObject.setLatLngs(polygonData.coordinatesPolygon.map(coord => coord.coords[0]))
                
                const marker = L.marker([e.latlng.lat, e.latlng.lng], {
                    draggable: true,
                    icon: this.customIcon
                }).addTo(this.map)

                polygonData.markerObject.push(marker)
                
                this.attachDragEvent(marker, newIndex)

                marker.on('click',() => this.deleteMarker(marker, newIndex))
                
                
                if(this.polygons[0].markerObject.length == 3) {
                    if(this.numbers == 1) {
                        ++this.numbers
                        let data = calculateTriangleCentroid(this.polygons[0].centerMarker)
                        this.center = data
                        
                        const customIconCenter = L.divIcon({
                            className: 'custom-marker',
                            html: '<div style="border: 2px solid black; border-radius: 50%; width: 13px; height: 13px;"><div style="border: 1px solid black; border-radius: 50%; width: 5px; height: 5px; margin: 2px; background-color: black;"></div></div>',
                            iconSize: [10, 10],
                            iconAnchor: [10, 10]
                        })
    
                        const iconCenter = L.marker(this.center, {
                            icon: customIconCenter,
                            draggable: true,
                        }).addTo(this.map)
    
                        this.markerCenter.push(iconCenter);
                        iconCenter.on('drag', () => {
                            let data = {center: this.markerCenter, polygon: this.polygons}
                            this.$emit('input', data)
                        })
                    }
                }
            }
        },
        attachDragEvent(marker, index) {
            marker.on('drag', (e) => {
                const newPos = e.target.getLatLng();

                this.polygons[0].coordinatesPolygon.forEach((e) => {
                    if(e.id == index) e.coords = [[newPos.lat, newPos.lng]] 
                })
            
                this.polygons[0].polygonObject.setLatLngs(this.polygons[0].coordinatesPolygon.map(coord => coord.coords[0]));
            });
            marker.on('drag', () => {
                let data = {center: this.markerCenter, polygon: this.polygons}
                this.$emit('input', data)
            })
        },
        stopCreateMarkerMain() {
            this.status = false
        },
        deleteMarker(marker, newIndex) {
            
            this.polygons[0].markerObject.forEach((e) => {
                if(e._leaflet_id == marker._leaflet_id) {
                    e.remove()
                }
            })

            this.polygons[0].coordinatesPolygon.forEach((e, index) => {
                if(e.id == newIndex) {
                    this.polygons[0].polygonObject._latlngs[0].splice(index, 1)
                    this.polygons[0].coordinatesPolygon.splice(index, 1)
                }
            })

            this.polygons[0].polygonObject.setLatLngs(this.polygons[0].polygonObject._latlngs)

        },
        clearPolygon() {
            if(this.polygons.length > 0) {
                let data = confirm('Все данные будут потеряны')
                if(data) {
                    this.status = false
                    this.mainClear()
                    return true
                }else {
                    return false
                }
            }else {
                this.status = false
                return true
            }
        },
        clearMarkerCenter() {
            if(this.markerCenter) this.markerCenter.forEach(element => element.remove());
            this.clearData()
        },
        mainClear() {
            if(this.polygons[0].polygonObject) this.polygons[0].polygonObject.remove()
            if(this.polygons[0].markerObject) this.polygons[0].markerObject.forEach((e) => {e.remove()})
            this.clearMarkerCenter()
        },
        clearData() {
            this.status = false
            this.number = 0
            this.numberMarket = 0
            this.numberPolygon = 0
            this.polygons = []
            this.center = []
            this.markerCenter = []
            this.numbers = 1
            this.customIcon = null
            this.polygon = []
        }
    },
}
</script>
