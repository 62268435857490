<template>
        <div class="text-white w-96 rounded-md">
            <h1 class="text-2xl px-3 font-semibold">Добавление быстрых адресов</h1>
            <div class="flex justify-between items-center my-4 px-3">
                <label class="text-sm">Название <i class="required" >*</i></label>
                <input placeholder="Название" class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text" v-model="fastAddress.name">
            </div>
            <div class="flex justify-between items-center my-4 px-3">
                <label class="text-sm">Почтовый индекс</label>
                <input placeholder="Почтовый индекс" class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text" v-model="fastAddress.postCode">
            </div>
            <div class="flex justify-between items-center my-4 px-3">
                <label class="text-sm">Категория <i class="required" >*</i></label>
                <v-select
                class="w-[270px] text-black bg-white"
                :options="categories"
                :reduce="category => category"
                v-model="fastAddress.category_id"
                placeholder="Категория"
                label="name"></v-select>
            </div>
            <!-- <div class="flex justify-between my-4 px-3">
                <input type="checkbox" class="mt-1.5 mr-2">
                <div>Показывать на карте вместо улицы и номера дома </div>
            </div>  -->
            <div class="flex justify-between items-center my-4 px-3">
                <label class="text-sm">Улица <i class="required" >*</i></label>
                <v-select
                    class="w-[270px] text-black bg-white"
                    :options="streets"
                    :reduce="street => street"
                    v-model="fastAddress.street_id"
                    label="displayName"
                    placeholder="Улица"
                    @input="getDescription()"
                />
            </div>
            <div class="flex justify-between items-center my-4 px-3">
                <label class="text-sm">Номер дома <i class="required" >*</i></label>
                <v-select
                class="w-[270px] text-black bg-white"
                :options="home_numbers"
                :reduce="number => number"
                v-model="fastAddress.number_id"
                placeholder="Номер дома"
                @input="getDescription()"
                label="houseNum"></v-select>
            </div>
            <div class="flex">
                <div class=" justify-between items-center my-4 px-3">
                    <div class="text-sm">Широта <i class="required" >*</i></div>
                    <input placeholder="Широта" disabled class="border rounded w-full text-sm text-black px-2 py-1 h-[40px]"
                    v-model="fastAddress.lat" type="text">
                </div>
                <div class=" justify-between items-center my-4 px-3">
                    <div class="text-sm">Долгота <i class="required" >*</i></div>
                    <input placeholder="Долгота" disabled class="border rounded w-full text-sm text-black px-2 py-1 h-[40px]" 
                    v-model="fastAddress.lng" type="text">
                </div>
            </div>
            <div class="my-1 px-3">
                <div class="text-sm">Описание <i class="required" >*</i></div>
                <textarea placeholder="Описание" class="border rounded w-full text-sm text-black px-2 py-1 h-[60px]" v-model="fastAddress.getDescription" type="text"></textarea>
            </div>
            <small class="flex px-3 text-base text-yellow-500" v-if="!outsidePolygon">Дом выходит из границ города</small>
            <!-- <div class="flex my-4 px-3">
                <div class="bg-white text-black rounded-sm mr-2 p-1 cursor-pointer" @click="showSynonyms">Синонимы ({{lines.length}})</div>
            </div> -->
            <div v-if="synonymModal">
                <h1 class="text-2xl px-3 font-semibold">Синонимы</h1>
                <div class="flex justify-between my-4 px-3" v-for="(line, index) in lines" :key="index">
                    <input class="w-80 text-black" type="text" v-model="line.name">
                    <div class="bg-white text-black rounded-sm mr-1 px-2 cursor-pointer"><i class="ri-delete-bin-line" @click="removeLine(index)"></i></div>
                </div>
                <div class="flex justify-end my-4 px-3">
                        <div class="bg-white text-black rounded-sm mr-1 py-1 px-3 cursor-pointer" @click="addLine">+</div>
                </div>
            </div>
            <div class="flex justify-between my-4 px-3">
                <div class="bg-white text-black rounded-sm mr-2 p-1 cursor-pointer" @click="$emit('update'), clearData()">Отмена</div>
                <div class="rounded mx-2 p-1 cursor-pointer" 
                :class="isActive ? 'bg-white text-black' : 'bg-black/40 text-white'" @click="addFastAdress">Сохранить</div>
            </div>
            <div class="flex my-6 px-3" v-if="addAny">
                <div class="bg-white text-black rounded-sm p-1 cursor-pointer text-sm px-2 py-1">Добавить</div>
            </div>
        </div>
</template>

<script>
export default {
    props: ['village_id', 'fastAddressCoor', 'outsidePolygon', 'addAny', 'categories'],
    data() {
        return {
            fastAddress: {
                name: '',
                category_id: null,
                street_id: null,
                number_id: null,    
                lat: this.fastAddressCoor.lat,
                lng: this.fastAddressCoor.lng,
                getDescription: '',
                postCode: ''
            },
            home_numbers: [],
            lines: [],
            synonymModal: false,
            streets: []
        }
    },
    computed: {
        isActive() {
            if(this.fastAddress.name &&
               this.fastAddress.category_id &&
               this.fastAddress.number_id &&
               this.fastAddress.street_id &&
               this.fastAddress.lat &&
               this.fastAddress.lng) {
                return true
               }
            else {
                return false
            }
        }
    },
    watch:{
        fastAddressCoor() {
            if(this.fastAddressCoor.length !== 0){
                let num = this.fastAddressCoor.indexOf(',')
                let end = this.fastAddressCoor.indexOf(']')
                this.fastAddress.lat = this.fastAddressCoor.substr(1, num-1)
                this.fastAddress.lng = this.fastAddressCoor.substring(num+1, end)
                this.getHomeNumbers()
                this.getStreets()
            }
        },
    },
    methods: {
        getHomeNumbers() {
            this.$http.get(`v1/poi/addresses?lng=${this.fastAddress.lng}&lat=${this.fastAddress.lat}`)
            .then((res) => {
                this.home_numbers = res.data
            })
            .catch((error) => {
                console.log(error);
            })
        },
        getStreets() {
            this.$http.get(`v1/streets/around`, { params: { lng: this.fastAddress.lng, lat: this.fastAddress.lat } })
            .then((res) => {
                this.streets = res.data
            })
            .catch((error) => {
                console.log(error);
            })
        },

        showSynonyms() {
            this.synonymModal = !this.synonymModal
        },
        addLine() {
            this.lines.push({name: ''})
        },
        removeLine(index) { 
            this.lines.splice(index, 1)
        },
        getDescription() {
            if(this.fastAddress.street_id && this.fastAddress.number_id) {
                this.fastAddress.getDescription = `${this.fastAddress.street_id.displayName}, ${this.fastAddress.number_id.houseNum}`
            }
        },
        addFastAdress() {
            let data = {
                name: this.fastAddress.name,
                categoryId: String(this.fastAddress.category_id.id),
                postCode: this.fastAddress.postCode,
                description: this.fastAddress.getDescription,
                lat: this.fastAddress.lat,
                lng: this.fastAddress.lng,
                addressCode: this.fastAddress.number_id.addressCode,
                streetCode: this.fastAddress.street_id.streetCode,
            }
            this.$http.post('v1/poi/points', data)
            .then(res =>{
                console.log(res);
                this.clearData()
                this.$emit('update')
                this.$emit('removeFastMarker')
                this.$toast.open({
                    message: 'Адрес успешно добавлен',
                    type: 'success',
                    position: 'top'
                });
            }).catch(err => {
                this.$toast.open({
                    message: JSON.stringify(err.response.data),
                    type: 'error',
                    position: 'top'
                });
                console.log(err);
            })
        },
        clearData() {
            this.fastAddress = {
                name: '',
                category_id: null,
                street_id: null,
                number_id: null,    
                lat: this.fastAddressCoor.lat,
                lng: this.fastAddressCoor.lng
            },
            this.home_numbers = [],
            this.lines = [],
            this.synonymModal = false
        }
    }
}
</script>