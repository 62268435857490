<template>
    <div>
        <div v-for="polygon in options" :key="polygon?.id">
            <l-marker @click="selectVillages(polygon)" :lat-lng="polygon" ref="marker"  @mouseout="closeLocalities" @mouseover="openPopup">
                <l-popup class="map-popup" :content="`${polygon.displayName} ${'<br/>'}ID: ${polygon.id}`"></l-popup>
                <l-icon>
                    <img :src="polygon.icon" alt="" srcset="">
                </l-icon>
            </l-marker>
        </div>
    </div>
</template>

<script>
import { LMarker, LIcon, LPopup } from 'vue2-leaflet';
import L from 'leaflet';
export default {
    components: {
        LMarker,
        LIcon,
        LPopup
    },
    props: ['map','options'],
    data() {
        return {
            polygons: [],
            is_active: true,
            editingPolygonId: null,
            markers: [],
            numberPolygon: 0
        }
    },
    methods: {
        refreshPolygon() { 
            this.polygons = this.options.map(option => ({
                id: option.id,
                vertices: option.polygon.coordinates[0].map(e =>({
                    id: ++this.numberPolygon, 
                    coords: [e[1], e[0]]
                })),
                polygonObject: null,
                editing: false,
                color: option.color,
                weight: option.lineWidth,
                editLatLng: false,
            }))
        },
        openPopup(event) {
            this.$refs.marker.forEach((e) => {
                if(e.latLng.lat == event.latlng.lat) {
                    e.mapObject.openPopup(event.latlng)
                }
            })
        },
        closeLocalities(event) {
            this.$refs.marker.forEach((e) => {
                if(e.latLng.lat == event.latlng.lat) {
                    e.mapObject.closePopup(event.latlng)
                }
            })
        },
        selectVillages(id, params) {
            if(id.id !== this.editingPolygonId) {
                this.clearData()
                this.createPolygon()
                this.$emit('isActivePolygonNP', id)
                this.colorPolygon(id)
            }else {
                if(params == 'cancelEdit') {
                    this.clearData()
                    this.polygons = []
                    this.numberPolygon = 0
                    this.refreshPolygon()
                    this.is_active = true
                    this.createPolygon()
                    this.colorPolygon(id)
                    this.selectPolygon(id.id)
                }
            }
        },
        colorPolygon(id) {
            this.polygons.forEach((e) => {
                if(e.id == id.id) {
                    e.polygonObject.setStyle({
                        color: '#ff3333',
                        fillColor: '#ff3333'
                    })
                }else {
                    e.polygonObject.setStyle({
                        color: '#3374FF',
                        fillColor: '#3374FF'
                    })
                }
            })
        },
        createPolygon() {
            this.refreshPolygon()
            if(this.is_active) {
                this.polygons.forEach((poly) => {
                    
                    const coords = poly.vertices.map(vertex => vertex.coords)

                    const polygon = L.polygon(coords, {
                        color: poly.color,
                        fillColor: poly.color,
                        fillOpacity: 0,
                        weight: poly.weight
                    }).addTo(this.map)
                        
                    poly.polygonObject = polygon
                    polygon.on('click', (e) => {
                        if(poly.editing) {
                            this.createMarker(e, poly, poly.id)
                        }
                    })
                })
                this.is_active = false
            }
        },
        deletePolygon() {
            this.polygons.forEach((e) => {if(e) e.polygonObject.remove(), e.editLatLng = false, this.is_active = true})
        },
        clearData() {
            this.polygons.forEach((e) => {if(e.polygonObject !== null) e.polygonObject.remove(), e.editLatLng = false, this.is_active = true})
            this.markers.forEach((e) => {if(e) e.remove()})
            this.markers = []
            this.editingPolygonId = null
        },
        selectPolygon(data) { 
            const polygon = this.polygons.find(p => p.id === data)

            if(!polygon) return 

            this.editingPolygonId = data
            polygon.editing = true            

            this.$emit('coorModal', {id: data, vertices: polygon.vertices, dragend: ''})

            const customIcon = L.divIcon({
                className: 'custom-marker',
                html: '<div style="margin-top: 7px; background-color: #ffff; width: 10px; height: 10px; border-radius: 10%; border: 2px solid black;"></div>',
                iconSize: [10, 10],
                iconAnchor: [10, 10]
            })

            this.markers.forEach(marker => marker.remove())
            this.markers = []
            

            polygon.vertices.forEach((coords) => {
                const marker = L.marker(coords.coords, {
                    draggable: true,
                    icon: customIcon
                }).addTo(this.map).on('click', () => this.removeMarker(polygon, coords.id))

                this.markers.push(marker)

                marker.on('drag', (e) => {
                    const newPos = e.target.getLatLng();                                                         
                    this.editLatLng = true
                    polygon.vertices.forEach((elem) => {
                        if(coords.id == elem.id) {
                            elem.coords = [newPos.lat, newPos.lng]
                        }
                    })
                    const updatedCoords = polygon.vertices.map(vertex => vertex.coords);
                    polygon.polygonObject.setLatLngs(updatedCoords)
                })
                marker.on('drag', () => {this.polygons.map(p => {if(p.editing) {this.$emit('coorModal', {id: data, vertices: polygon.vertices, dragend: 'dragend'})}})})
            })
        },
        cancelEdit() {
            this.clearData()
            this.createPolygon()
        },
        removeMarker(polygon,  id) {
            if(!polygon) return
            polygon.vertices = polygon.vertices.filter((e) => e.id !== id)
            this.selectPolygon(polygon.id)
        },
        createMarker(e, poly, id) {
            let newPos = e.latlng
            let number = ++this.numberPolygon
            poly.vertices.push({ id: number, coords: [newPos.lat, newPos.lng]})
            const updatedCoords = poly.vertices.map(vertex => vertex.coords);
            poly.polygonObject.setLatLngs(updatedCoords)
            this.selectPolygon(id)
        },
        undoСhanges(polyUndoChanges) {
            let polygonChanges = polyUndoChanges
            this.selectVillages(polygonChanges[0], 'cancelEdit')
        }
    },
}
</script>

<style>
.map-popup,
.leaflet-popup-content-wrapper {
    background: #000000b3;
    color: #fffbfb;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, .3);
}
.map-popup,
.leaflet-popup-content-wrapper, .leaflet-popup-tip {
    background: #000000b3;
    color: #fffbfb;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, .3);
}
</style>