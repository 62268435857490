<template><div></div></template>
<script>
import L from 'leaflet';
import 'leaflet.markercluster';
import homeFast  from '@/assets/img/homeFast.png'
import houseBlack from '@/assets/img/house-black.png'
import fillGreen from '@/assets/img/fill-green.png' 

export default {
    props: ['map', 'addresses', 'fastAddresses', 'zoom', 'param'],
    data() {
        return {
            displayedMarkersAddresses: [],
            displayedMarkersAddressesFast: [],
            markerClusterGroup: null,
            clusterOptions: {
                showCoverageOnHover: true,
                maxClusterRadius: 30,
                disableClusteringAtZoom: 18  
            },
            idMarkerAddresses: null,
            idMarkerFast: null,
            marker: null,
            dataEntranceActive: true,
            markerEntrance: [],
            dataEntrance: [],
            activeMaps: true,
            numberEntrance: null,
            markerFast: [],
            markerAddresses: [],
            animationFrameId: null,
        };
    },
    mounted() {
        this.markerClusterGroup = L.markerClusterGroup(this.clusterOptions);

        this.map.on("zoomend", () => {
            if(this.zoom < 17) {
                this.handleMapEvent()
            }
        });
        this.map.on("moveend", () => {
            if(this.zoom > 16) {
                this.handleMapEvent()
            }
        });
    },
    methods: {
        handleMapEvent() {
            if (this.animationFrameId) {
                cancelAnimationFrame(this.animationFrameId);
            }

            this.animationFrameId = requestAnimationFrame(() => {
                this.updateMarkers();
            });
        },

        updateMarkers() {
            const bounds = this.map.getBounds();
            this.deleteAllMarker()

            const combinedMarkers = [];

            if(this.addresses.length > 0) {
                this.displayedMarkersAddresses = this.addresses.filter(address =>
                    bounds.contains([address.lat, address.lng])
                );
                if(this.idMarkerAddresses) {
                    const index = this.displayedMarkersAddresses.findIndex(e => e.id === this.idMarkerAddresses);
                    if(index !== -1) this.displayedMarkersAddresses.splice(index, 1)  
                } 
                combinedMarkers.push(...this.displayedMarkersAddresses.map(addresses => ({
                    lat: addresses.lat,
                    lng: addresses.lng,
                    iconUrl: houseBlack,
                    onClick: () => {
                        if(this.param == 'address') {
                            if (this.dataEntranceActive) {
                                this.addressesID(addresses);
                            }
                        }else {
                            console.log(this.param);
                        }
                    },
                })));
            }

            if(this.fastAddresses.length > 0) {
                this.displayedMarkersAddressesFast = this.fastAddresses.filter(fast => 
                    bounds.contains([fast.lat, fast.lng])
                );
                if(this.idMarkerFast) {
                    const index = this.displayedMarkersAddressesFast.findIndex(e => e.id === this.idMarkerFast)
                    if(index !== -1) this.displayedMarkersAddressesFast.splice(index, 1)  
                } 
                combinedMarkers.push(...this.displayedMarkersAddressesFast.map(fast => ({
                    lat: fast.lat,
                    lng: fast.lng,
                    iconUrl: homeFast,
                    onClick: (event) => {
                        if (this.dataEntranceActive) {
                            this.fastID(fast, event);
                        }
                    },
                })))
            }

            this.renderCombinedMarkers(combinedMarkers);

        },
        renderCombinedMarkers(markers) {
            if (this.markerClusterGroup) {
                this.markerClusterGroup.clearLayers();
            }

            markers.forEach(({ lat, lng, iconUrl, onClick }) => {
                const marker = L.marker([lat, lng], {
                    icon: L.icon({
                        iconUrl,
                    }),
                }).on('click', onClick);
                this.markerClusterGroup.addLayer(marker);
            });

            this.map.addLayer(this.markerClusterGroup);
        },     
        addressesID(id, index) {
            this.displayedMarkersAddresses.splice(index, 1)
            this.idMarkerAddresses = id.id
            this.updateMarkers()
            this.createMarker(id)
            this.$emit('openModalAddress', id)
        },
        fastID(id, index) {
            this.displayedMarkersAddressesFast.splice(index, 1)
            this.idMarkerFast = id.id
            this.updateMarkers()
            this.createMarker(id)
            this.$emit('selectFastHouse', id)
        },
        createMarker(id) {
            if(this.marker) {
                this.marker.remove()
            }

            const marker = L.marker(id, {
                draggable: true,
                icon: L.icon({
                    iconUrl: fillGreen
                })
            }).addTo(this.map)

            this.marker = marker

            marker.on('dragend', (e) => {
                this.$emit('coordinateDragend', e.target._latlng, this.param)
            })
        },
        createEntrance(data) {
            if(data) {
                this.dataEntrance = data
                this.fundataEntranceActive()
            }

            this.clearEntranceActive()

            function customIcon(elem) {
                return L.divIcon({
                    className: "green-icon",
                    html: `<span class="icon-text">${elem.idCustom ? elem.idCustom : elem.name}</span>`,
                    iconSize: [10, 10],
                    iconAnchor: [10, 10]
                })
            }
            
            this.dataEntrance.forEach((elem) => {
                const markerEntrance = L.marker(elem, {
                    draggable: true,
                    icon: customIcon(elem)
                }).addTo(this.map)
                this.markerEntrance.push(markerEntrance)

                markerEntrance.on('drag', (e) => {
                    this.$emit('editCoordsEntrance', elem.idCustom ? elem.idCustom : elem.id, e.target._latlng)
                })
            })            
        },
        activeMap(id) {
            this.numberEntrance = id
            this.activeMaps = false
            this.map.on('click', (e) => {
                if(this.activeMaps == false) {
                    this.dataEntrance.forEach((elem) => {
                        if(elem.idCustom == this.numberEntrance) {
                            let coords = e.latlng
                            elem.lat = coords.lat
                            elem.lng = coords.lng
                        }
                    })
                    this.createEntrance()
                    this.activeMaps = true
                    this.$emit('addEntranceActiveButton')
                }
            })
        },
        fundataEntranceActive() {
            if(this.dataEntranceActive) {
                this.dataEntranceActive = false
            }else {
                this.dataEntranceActive = true
            }
        },
        clearmarker() {
            if(this.marker) this.marker.remove()
            this.marker = null,
            this.idMarkerAddresses = null,
            this.idMarkerFast = null,
            this.updateMarkers()
        },
        deleteAllMarker() {
            if(this.markerClusterGroup) {
                this.markerClusterGroup.clearLayers()
            }

            this.markerFast.forEach((elem) => {
                if(elem) {
                    elem.remove()
                } 
                this.markerFast = []
            })

            this.markerAddresses.forEach((elem) => {
                if(elem) {
                    elem.remove()
                }
                this.markerAddresses = []
            })
        },
        clearEntranceActive() {
            if(this.markerEntrance) {
                this.markerEntrance.forEach(e => e.remove())
                this.markerEntrance = []
            }
            this.map.off('click', this.activeMap);
            this.dataEntranceActive = true
        }
    },
};
</script>
