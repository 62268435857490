<template>
    <div class="text-white rounded-md">
        <h1 class="text-2xl px-3 font-semibold">Добавление домов, улиц</h1>
        <div class="flex justify-between items-center my-4 px-3">
            <label class="text-sm">Улица <i class="required">*</i></label>
            <v-select
                class="w-[270px] text-black bg-white"
                :options="streets"
                :reduce="street => street.streetCode"
                v-model="address.streetCode"
                label="displayName"
            >
                <template v-slot:no-options>
                    <div>
                        Нет данные в базе
                   </div>
                </template>
            </v-select>
        </div>
        <button disabled class="border-solid border-[1px] border-white bg-white text-black px-2 py-1 rounded-[2px] ml-[120px]">
            Редактор улиц
        </button>
        <div>
            <div class="flex justify-between items-center my-4 px-3">
                <label class="text-sm mr-4">Номер дома <i class="required">*</i></label>
                <input ref="input" class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text" v-model="address.number">
            </div>
            <div class="flex justify-between items-center my-4 px-3">
                <div class="text-sm mr-4">Координаты <i class="required">*</i></div>
                <input class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text" disabled :value="coor">
            </div>
            <button @click="dopInfoToHome = !dopInfoToHome" class="border-solid border-[1px] border-white bg-white text-black px-2 py-1 rounded-[2px] ml-[120px] hover:bg-slate-200">
                Доп опции
            </button>
            <div v-if="dopInfoToHome">
                <div class="flex justify-between items-center my-4 px-3">
                <div class="text-sm mr-4">Владение </div>
                <input class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text" v-model="address.vladenie">
                </div>
                <div class="flex justify-between items-center my-4 px-3">
                    <div class="text-sm mr-4">Корпус </div>
                    <input class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text" v-model="address.korpus" >
                </div>
                <div class="flex justify-between items-center my-4 px-3">
                    <div class="text-sm mr-4">Cтроение </div>
                    <input class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text" v-model="address.stroenie">
                </div>
                <div class="flex justify-between items-center my-4 px-3">
                    <div class="text-sm mr-4">Cооружения </div>
                    <input class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text" v-model="address.soorujenie">
                </div>
            </div>
        </div>
        <div class="flex items-center my-6 px-3" v-if="error">
            <small class="text-red-600 mr-3 text-base">Такой дом существует</small>
            <div class="bg-white text-black rounded-sm p-1 cursor-pointer text-sm px-2 py-1">Показать</div>
        </div>
        <div class="flex justify-between my-6 px-3">
            <button class="border-solid border-white border-[2px] bg-white text-black rounded-[8px] text-sm px-2 py-2" @click="$emit('addLocation'), clearData()" >Отмена</button>
            <button class="rounded-sm text-sm px-2 py-2" :class="isActive ? 'disabled' : 'nodisabled'" :disabled="isActive" @click="postAddresses">Сохранить</button>
        </div>
    </div>
</template>

<script>
// import FormStreet from './FormStreet.vue'

export default {
    props: ['village_id', 'coor'],
    data(){
        return{
            streets: [],
            address: {
                number : "",
                vladenie : "",
                korpus : "",
                stroenie : "",
                soorujenie : "",
                streetCode : "",
                lat : "",
                lng : ""
            },
            error: null,
            errorAdress: null,
            isActive: false,
            dopInfoToHome: false,
        }
    },
    methods: {
        getStreet() {
            this.$http.get(`v1/polygons/${this.village_id}/streets`)
            .then((res) => {
                this.streets = res.data;
            })
            .catch((error) => {
                console.log(error);
            })
        },
        clearData() {
            this.address = {
                number : "",
                vladenie : "",
                korpus : "",
                stroenie : "",
                soorujenie : "",
                streetCode : "",
                lat : "",
                lng : ""
            },
            this.error = null,
            this.errorAdress = null,
            this.isActive = true,
            this.dopInfoToHome = false,
            this.streets = []
        },
        postAddresses() {
            this.isActive = true
            this.$http.post('v1/points', this.address)
            .then(() => {
                this.$toast.open({
                    message: 'Дом успешно добавлен',
                    type: 'success',
                    position: 'top'
                });
                this.clearData()
                this.$emit('addLocation')
            })
            .catch(err => {
                console.log(err);
                this.$toast.open({
                    message: 'Ошибка в добавлении дома',
                    type: 'error',
                    position: 'top'
                });
                // if(err.response.data.errors.name == this.address.name) {
                //     this.error = true
                //     this.errorAdress = err.response.data.errors
                // }   
            })
            .finally(() => {
                this.isActive = false
            })
        },
    }, 
    watch:{
        coor() {
            if(this.coor){
                this.address.lat = this.coor.substr(1, this.coor.indexOf(',')-1)
                this.address.lng = this.coor.substring(this.coor.indexOf(',')+1, this.coor.indexOf(']'))
            }
        },
    },
}
</script>

<style>
.round{
    border-radius: 7px;
}
input{
    height: 30px;
}
</style>