<template>
    <div>
        <div class="text-white rounded-md">
            <h1 class="text-2xl px-3 font-semibold">Редактирование границ города</h1>
            <div class="flex justify-between items-center my-5 px-3">
                <label class="mr-3">WKT</label>
                <input id="coords" class="border text-black mx-0 w-[267px] h-[40px]" disabled type="text" placeholder="WKT" v-model="coordinates.vertices">
                <i class="ri-file-copy-line cursor-pointer" @click="copy"></i>
            </div>
            <div class="text-[#FFA500] text-[13px] text-center" v-if="!coordinatesLatLon">
                Вы точно хотите сохранить изменения. <br/> Перед сохранения скопируйте данные полигон <br/>чтобы если возникнет ошибка смогли восстановить
            </div>
            <div class="flex my-4 px-3">
                <button class="bg-white text-black rounded-sm p-1 ml-auto" @click="undoСhanges()" :disabled="coordinatesLatLon" :style="coordinatesLatLon ? {backgroundColor: 'darkgrey'} : {}" >Отменить изменения</button>
                <button class="bg-black/40 text-white rounded ml-2 p-1" :disabled="coordinatesLatLon" :style="coordinatesLatLon ? {backgroundColor: 'darkgrey', color: 'black'} : {}"  @click="savePolygon">Сохранить</button>
            </div>
        </div>
    </div>
</template>
<script>
import converter from '@/util/converterToGeojsonOrPolygon.js'
export default {
    props: ['village'],
    data() {
        return {
            coordinates: this.village,
            coordinatesLatLon: true
        }
    },
    methods: {
        savePolygon() {
            let save = confirm("Вы дествитель хотите изменить?");
            if(save) {
                this.$http.get(`v1/polygons/${this.village.id}`)
                    .then(res => {
                        let data = res.data
                        data.geometry = converter.encodePolyline(converter.converterToGeojsonOrPolygon(this.coordinates.vertices))
                        this.updatePolygon(data)
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            }
        },
        updatePolygon(data) {
            this.$http.patch(`v1/polygons/${data.id}`, data)
            .then(() => {
                this.$emit('close')
            })
            .catch(err => {
                console.log(err);
            })
        },
        copy(){
            navigator.clipboard.writeText(this.coordinates.vertices);
        },
        cancelEdit() {
        },
        undoСhanges() {
            this.coordinatesLatLon = true
            this.$emit('undoСhanges', this.coordinates)
        }
    },
    watch: {
        village(newValue) {
            if(newValue.dragend == 'dragend') this.coordinatesLatLon = false
            let data = newValue
            data.vertices = converter.converterToGeojsonOrPolygon(newValue.vertices)
            this.coordinates = data
        }
    }
}
</script>